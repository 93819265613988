<template>
  <div id="page">
    <section class="product-list-heading box-my-account mb-0">
      <div class="container-fluid">
        <div class="row">
          <ProfileNavbar
            :parentData="userData.full_name"
          ></ProfileNavbar>
          <div class="col-md-9 py-5 px-lg-5">
            <h2>My Liz-Points</h2>
            <template v-if="isLoaded">
              <div v-if="loyaltyPointListData.length > 0" class="mt-4">
                <div class="my-liz-points">
                  <div class="point-view">
                    <h5 class="mb-0">Points</h5>
                    <div class="d-flex align-items-center">
                      <h2 class="m-0 mt-0 text-gold roboto">
                        {{ formatPrice(myPoint) }}
                      </h2>
                      <p class="mb-0 ml-auto">
                        <template v-if="soonExpired">
                          {{
                            formatPrice(soonExpired.point - soonExpired.used)
                          }}
                          points will expire on
                          {{ formatDate(soonExpired.expired_date) }}
                        </template>
                        <template v-else> No points will expire soon </template>
                      </p>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Point History</th>
                          <th>Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(loyaltyData, index) in loyaltyPointListData"
                          :key="index"
                        >
                          <td>
                            <h4 class="roboto mb-0">{{ loyaltyData.note }}</h4>
                            <p class="mb-0">
                              {{ formatDate(loyaltyData.created_at) }}
                            </p>
                          </td>
                          <td>
                            <template v-if="loyaltyData.expired">
                              <h3 class="mb-0 red">
                                {{ formatPrice(loyaltyData.point) }}
                              </h3>
                              <p class="mb-0 red">Expired</p>
                            </template>
                            <template v-else-if="loyaltyData.point < 0">
                              <h3 class="mb-0 red">
                                {{ formatPrice(loyaltyData.point) }}
                              </h3>
                              <p class="mb-0 red">Liz-Ponts Used</p>
                            </template>
                            <h3 class="green" v-else>
                              {{ formatPrice(loyaltyData.point) }}
                            </h3>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div v-else class="row justify-content-center mt-5">
                <div class="col-md-6">
                  <img src="@/assets/img/no-transaction.png" alt="" />
                  <h4 class="roboto">
                    You haven't earned any lizpoints,
                    <router-link to="/">let's go shopping!</router-link>
                  </h4>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ProfileNavbar from "@/components/mains/ProfileNavbar.vue";
import axios from "axios";
import moment from "moment";

export default {
  components: {
    ProfileNavbar,
  },
  data() {
    return {
      isLoaded: false,
      loyaltyPointListData: [],
      myPoint: 0,
      soonExpired: undefined,
      token: this.$store.getters.getUserToken,
    };
  },
  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_URL;
    },
    apiKey() {
      return process.env.VUE_APP_API_KEY;
    },
    userData() {
      return this.$store.getters.getUserData;
    }
  },
  mounted() {
    this.getLoyaltyListData();
    this.getCountLizPoint();
    this.getSoonExLizPoint();
  },
  methods: {
    getLoyaltyListData() {
      axios
        .get(this.apiUrl + "users/loyaltypoint/history", {
          headers: {
            api_key: this.apiKey,
            token: this.token,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.loyaltyPointListData = response.data.data;
          this.isLoaded = true;
        })
        .catch((error) => console.log(error));
    },
    getCountLizPoint() {
      axios
        .get(this.apiUrl + "users/loyaltypoint/countpoint", {
          headers: {
            api_key: this.apiKey,
            token: this.token,
            "Content-Type": "application/json",
          },
        })
        .then((response) => (this.myPoint = response.data.data))
        .catch((error) => console.log(error));
    },
    getSoonExLizPoint() {
      axios
        .get(this.apiUrl + "users/loyaltypoint/soonexpired", {
          headers: {
            api_key: this.apiKey,
            token: this.token,
            "Content-Type": "application/json",
          },
        })
        .then((response) => (this.soonExpired = response.data.data));
    },
    formatPrice(value) {
      return value ? value.toLocaleString("id") : value;
    },
    formatDate(value) {
      return moment(String(value)).format("D MMMM YYYY");
    },
  },
};
</script>

